<template>
  <div class="customer">
    <div class="top">
      <div class="querybox">
        <el-row :gutter="20">
          <el-col :span="6">
            <p>俱乐部名称:</p>
            <el-input
              v-model="queryParams.clubName"
              placeholder="请输入俱乐部名称"
              clearable
            ></el-input>
          </el-col>
          <el-col :span="6">
            <p>课程名称:</p>
            <el-input
              v-model="queryParams.courseName"
              placeholder="请输入课程名称"
              clearable
            ></el-input>
          </el-col>
          <el-col :span="6">
            <p>课程状态:</p>
            <el-select
              v-model="queryParams.courseReviewStatus"
              placeholder="请选择课程状态"
              clearable
            >
              <el-option
                v-for="item in getDictDatas(
                  DICT_TYPE.COURSE_REVIEW_STATUS
                ).filter((el) => el.value < 2)"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
      <div class="btnbox">
        <el-button
          type="primary"
          icon="el-icon-search"
          class="blue-btn"
          @click="search"
          >查询</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-refresh-right"
          class="gray-btn"
          @click="resetFn"
          >重置</el-button
        >
      </div>
    </div>
    <div class="content">
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <!-- <el-table-column type="index" width="50" label="编号"></el-table-column> -->
        <el-table-column prop="coverImg" label="课程封面">
          <template slot-scope="{ row }">
            <div class="logo">
              <img :src="row.coverImg" alt="" />
              <!-- {{ row.clubLogo }} -->
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="courseName" label="课程名称"> </el-table-column>
        <el-table-column prop="clubName" label="俱乐部名称"> </el-table-column>
        <!-- <el-table-column prop="clubName" label="发布时间"> </el-table-column> -->
        <el-table-column prop="epId" label="课程类型">
          <template slot-scope="{ row }">
            <p>
              <el-tag
                :type="
                  row.courseCategory === 1
                    ? 'warning'
                    : row.courseCategory === 2
                    ? 'success'
                    : ''
                "
                >{{ row.courseCategory_dictText }}</el-tag
              >
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="responsiblePersonPhone"
          label="负责人电话"
        ></el-table-column>
        <el-table-column prop="originalPrice" label="课程金额">
          <template slot-scope="{ row }">
            <p>￥ {{ row.originalPrice }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="courseReviewStatus" label="审核状态">
          <template slot-scope="{ row }">
            <p>
              <span v-if="row.courseReviewStatus === 0" style="color: #5e83f5"
                >待审核</span
              >
              <span v-if="row.courseReviewStatus === 1" style="color: #ee4242"
                >已拒绝</span
              >
            </p>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-popconfirm
              title="确定通过该课程审核吗？"
              popper-class="customerdel-popover"
              @confirm="cancelOrder(scope.row, 2)"
              v-if="Number(scope.row.courseReviewStatus) === 0"
            >
              <el-button class="textblue-btn" slot="reference">通过</el-button>
            </el-popconfirm>
            <el-popconfirm
              title="确定拒绝通过该课程审核吗？"
              popper-class="customerdel-popover"
              @confirm="cancelOrder(scope.row, 1)"
              v-if="Number(scope.row.courseReviewStatus) === 0"
            >
              <el-button class="textred-btn" slot="reference">拒绝</el-button>
            </el-popconfirm>
            <el-button
              class="text-btn"
              type="text"
              @click="checkDetail(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <pagination
        v-show="total > queryParams.pageSize"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="init"
      />
    </div>
  </div>
</template>

<script>
import { getCourseList, setCheckCourse } from "@/api/course";
export default {
  data() {
    return {
      queryParams: {
        type: 2,
        pageNum: 1,
        pageSize: 10,
        clubName: "",
        courseName: "",
        courseReviewStatus: null,
      },
      tableData: [],
      total: 0, //总数
      businessLicense: null,
      IMgdialogVisible: false,
    };
  },
  async created() {
    this.init();
  },
  mounted() {},
  methods: {
    //获取课程列表
    async init() {
      // console.log(1111);
      const res = await getCourseList(this.queryParams);
      this.tableData = res.result.records;
      this.total = res.result.total;
    },
    // 查询
    search() {
      this.queryParams.pageNum = 1;
      this.init();
    },
    //重置
    resetFn() {
      this.queryParams.courseReviewStatus = null;
      this.queryParams.courseName = "";
      this.queryParams.clubName = "";
      this.queryParams.pageNum = 1;
      this.init();
    },
    // 上下架
    async cancelOrder(item, status) {
      const params = {
        courseReviewStatus: Number(status),
        id: item.id,
      };
      await setCheckCourse(params);
      // this.$refs[`${text}-popover-${index}`].doClose();
      this.init();
    },
    // 查看详情
    checkDetail(row) {
      this.$router.push({
        path: "/coursedetail",
        query: {
          id: row.id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.customer {
  .top {
    display: flex;
    justify-content: space-between;
    // border-bottom: 1px solid #0000000d;
    .querybox {
      width: 90%;
      ::v-deep .el-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .el-col {
          display: flex;
          align-items: center;
          p {
            color: #333333;
            line-height: 22px;
            margin-right: 16px;
            white-space: nowrap;
          }
          .el-input,
          .el-range-editor {
            width: 256px;
          }
          input::-webkit-input-placeholder {
            /* WebKit browsers */
            color: #86909c;
          }
          .el-input__inner,
          .el-range-input {
            background: #f5f6f6;
            border: none;
            // color: #bbbbbb;
          }
          .tabul {
            margin: 16px 0;
            li {
              margin-right: 24px;
              white-space: nowrap;
            }
          }
        }
      }
    }
    .btnbox {
      display: flex;
      // flex-direction: column;
      padding-bottom: 10px;
      .el-button {
        padding: 5px 0;
        width: 84px;
        margin-right: 12px;
        border-radius: 6px;
      }
    }
  }
  .content {
    padding-top: 20px;
    .textred-btn {
      margin: 0 5px;
    }
    .logo {
      display: flex;
      justify-content: center;
      img {
        width: 68px;
        height: 60px;
      }
    }
  }
}
</style>
